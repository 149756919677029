<template>
  <div style="position: fixed; top: 0; bottom: 0; left: 0; right: 0; z-index: 10000; background-color: grey;">
    <tracker-map ref="trackerMap" v-if="show" style="width: 100%; height: 100%;" :toggles="toggles" :vehicles="vehicles" :token="token"></tracker-map>
  </div>
</template>

<script>
import axios from "axios";
import TrackerMap from "../../../components/map";

export default {
  name: "admin.tabletmap",
  components: { TrackerMap },
  data(){
    return {
      vehicles: [],
      token: null,
      show: false,
      toggles: {
        office: true,
        engineer: true,
        personal: true,
        jobs: false
      }
    }
  },
  mounted(){

    axios.get(`${process.env.VUE_APP_API_URL}/v1/map`)
        .then(response => {
          this.vehicles = response.data.vehicles;
          this.token = response.data.token;
          this.show = true;
        })
        .catch(error => {
          this.$error("Failed to load vehicles", error);
        })

  },
  methods: {
    updateSpeed(data){
      let vehicle = this.vehicles.filter((v) => {
        return parseInt(data.vehicle.id) === parseInt(v.id);
      })[0];
      if(vehicle){
        vehicle.lastLocation.gps.speed = data.speed;
      }
    }
  }
}
</script>

<style scoped>

</style>