<template>
  <div id="map" :style="{width: width + '%', height: height + '%'}"></div>
</template>

<script>
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {Point} from "ol/geom";
import {Feature} from "ol";
import {fromLonLat} from "ol/proj";
import {Stroke, Style, Fill, Text} from "ol/style";
import CircleStyle from "ol/style/Circle";
import {FullScreen, defaults as defaultControls} from 'ol/control';
import axios from 'axios';
// import Point from 'ol/geom/Point';
// import Feature from 'ol/Feature';
// import * as Proj from 'ol/proj';
// import VectorLayer from 'ol/layer/Vector';
// import VectorSource from 'ol/source/Vector';
// import {Icon, Style} from 'ol/style';
export default {
  name: 'Map',
  props: {
    width: Number,
    height: Number,
    token: String
  },
  data(){
    return {
      x: 383018,
      y: 5967425,
        map: null,
        positions: [],
        layer: null,
      socket: null,
      vectorSource: null,
      markersLayer: null
    };
  },
  mounted() {

    let centerPoint = new Point(
        fromLonLat( [-3.4874533187956307,54.05328679392503])
    )
      this.map = new Map({
          target: 'map',
          controls: defaultControls().extend([new FullScreen()]),
          layers: [
              new TileLayer({
                  source: new XYZ({
                      url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"
                  })
              })
          ],
          view: new View({
              center: centerPoint.getCoordinates(),
              zoom: 7
          })
      });

    this.vectorSource = new VectorSource({});

    this.markersLayer = new VectorLayer({
      source: this.vectorSource,
    });

    this.map.addLayer(this.markersLayer);

    this.map.on('click', (evt) => {
      let feature = this.map.forEachFeatureAtPixel(evt.pixel, function(feature) {
        return feature;
      });
      if (feature) {
        let name = feature.get('name');
        if(name && name.includes('JOB_MARKER_')){
          let jobId = name.replace('JOB_MARKER_', '');
          // Open in new tab
          window.open(`/p/jobs/${jobId}`, '_blank');
        }
        // this.$emit('clickOnVehicle', feature.get('name'));
      }
    });

    this.loadJobMarkers();
    this.loadVehicleMarkers();

  },
  methods: {
    loadJobMarkers(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/map/jobs`)
      .then(response => {
        
        response.data.markers.forEach((m) => {
          this.addMarker(m.latitude, m.longitude, 'JOB_MARKER_' + m.id, `JR #${m.id}\n${m.name}`,0, 'Job', m.assignedUsers)
        })

      })
      .catch(error => {
        this.$error("Failed to load job markers", error);
      })
    },
    loadVehicleMarkers(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/map/vehicles`)
      .then(response => {
        
        response.data.vehicles.forEach((v) => {
          if(v.vehicle_status.location == null){
            console.log('No location for', v.registration);
          }else{
            this.addMarker(v.vehicle_status.location.latitude, v.vehicle_status.location.longitude, v.id, v.registration, 0, 'Engineer');
          }
          // this.addMarker(m.latitude, m.longitude, m.tracker_imei, m.name, m.rotation, m.type)
        })

      })
    },
    addMarker(lat, long, imei, label = "", rotation, type, assigned = 0){
      let point1 = new Point(
          fromLonLat([long, lat])
      );
      point1.rotate((rotation * Math.PI / 180), fromLonLat([long, lat]))
      let newMarker = new Feature({
        geometry: point1,
        name: imei
      })

      let color = 'black';
      switch(type){
        case 'Engineer':
          color = 'green';
          break;
        case 'Office':
          color = 'orange';
          break;
        case 'Personal':
          color = 'red';
          break;
        case 'Job':
          color = 'black';
          break;
      }

      if(type == 'Job' && assigned > 0){
        color = 'orange';
      }

      let style = new Style({
        image: new CircleStyle({
          radius: 15,
          fill: new Fill({color: color}),
          stroke: new Stroke({
            color: 'white', width: 2
          })
        }),
        text: new Text({
          text: label,
          scale: 1.3,
          fill: new Fill({
            color: '#fff'
          }),
          stroke: new Stroke({
            color: '0',
            width: 3
          }),
          offsetY: 20
        })
      });

      
      newMarker.setStyle(style);
      

      this.vectorSource.addFeature(newMarker);

      this.positions.push({
        imei: imei,
        longitude: long,
        latitude: lat,
        marker: newMarker,
        type: type,
        lastStyle: style
      })
    },
    hideMarker(vehicle){
      let pos = this.positions.filter((p) => {
        return p.imei == vehicle.tracker_imei;
      })[0];
      pos.setStyle(null);
      pos.hidden = true;
    },
    showMarker(vehicle){
      let pos = this.positions.filter((p) => {
        return p.imei == vehicle.tracker_imei;
      })[0];
      pos.setStyle(pos.lastStyle);
      pos.hidden = false;
    },
    toggleMarkers(toggle, name){
      console.log("Toggling", name);
      let markers = this.positions.filter((p) => {
        return p.type === name;
      });
      if(toggle === true){
        markers.forEach((m) => {
          m.marker.setStyle(m.lastStyle);
          m.marker.hidden = false;
        })
      }else{
        markers.forEach((m) => {
          m.marker.setStyle(null);
          m.marker.hidden = true;
        })
      }
    },
    clickOnVehicle(vehicle){
      let position = this.positions.filter((p) => {
        return p.imei === vehicle.tracker_imei;
      })[0];
      if(position){
        console.log(position);
        console.log(position.marker.getGeometry().flatCoordinates);
        this.map.getView().setCenter(position.marker.getGeometry().flatCoordinates);
        this.map.getView().setZoom(21);
      }
    }
  },
  watch: {
    toggles: {
      handler(){
        this.toggleMarkers(this.toggles.office, 'Office');
        this.toggleMarkers(this.toggles.engineer, 'Engineer');
        this.toggleMarkers(this.toggles.personal, 'Personal');
        this.toggleMarkers(this.toggles.jobs, 'Job');
      },
      deep: true
    }
  }
}
</script>